
<template>
    
    <div class="mg">
        <!-- <div>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/training-plan' }">训练计划</el-breadcrumb-item>
        </el-breadcrumb>
            
        </div> -->
        <div class="plan">    
            <table width="1200px" border="0">
        <tr>
            <td colspan="2" style="background-color:#FFA500; height: 100px; text-align: center;">
                <h2 >训练计划</h2>
            </td>
        </tr>

        <tr>
            <td style="background-color:#FFD700;width:600px;text-align: center;">
                <a href="#C1">派燃烧体育生(100m)训练计划</a>
                
            </td>
            <td style="background-color:#eeeeee;height:100px;width:600px;text-align: center;">
                <a href="#C2">派燃烧体育生(800m)训练计划</a>
            </td>
        </tr>
        <tr>
            <td style="background-color:#FFD700;width:600px;text-align: center;">
                <a href="#C3">派燃烧体育生(铅球)训练计划</a>
            </td>
            <td style="background-color:#eeeeee;height:100px;width:600px;text-align: center;">
                <a href="#C4">派燃烧体育生(跳远)训练计划</a>
            </td>
        </tr>
        <tr>
            <td style="background-color:#FFD700;width:600px;text-align: center;">
                <a href="#C5">美国约翰逊（前四百米记录保持者）冬训训练计划 </a>
                
            </td>
            <td style="background-color:#eeeeee;height:100px;width:600px;text-align: center;">
                <a href="#C6">国家队教练 冬训训练计划 </a>
            </td>
        </tr>
        </table>
        </div>

        <div class="wenben" οncοntextmenu='return false' οndragstart='return false' 
                onselectstart ='return false' οnselect='document.selection.empty()' 
                οncοpy='document.selection.empty()' onbeforecopy='return false' οnmοuseup='document.selection.empty()'>
            <div class="wenzhang">
                <h2 id="C1" style="text-align: center;">派燃烧体育生训练计划(100m）</h2>
            </div>
            
                
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <div class="video11">
                    <video controls="controls" autoplay="autoplay"   muted="muted"
                    width="1200px" height="700px"
                    src="@/assets/images/plan100.mp4" alt="视频1" />
                </div>
                <h4 >周一</h4><br>
                1. 慢跑热身 拉伸韧带 活动关节 <br> 
                2. 跑的专门性练习（该系列练习可每天稍加练习）<br> 
                <pre>           (1) 小步跑 30m×2×2 组</pre>    <br>
                <pre>           (2) A式跳跃 30m×2×2 组</pre>    <br>
                <pre>           (3) B式跳跃 30m×2×2 组</pre>    <br>
                <pre>           (4) 高抬腿 30m×2×2 组</pre>    <br>
                <pre>           (5) 后蹬跑 30m×2×2 组</pre>    <br>
                <pre>           (6) 直腿跑 30m×2×2 组</pre>    <br>
                <pre>           (7) 车轮跑 30m×2×2 组</pre>    <br>
                <pre>           (8) 加速跑 30m×3×2 组</pre>    <br>
                            
                3. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周二</h4><br> 
                1.慢跑热身 拉伸韧带 活动关节  <br>
                2. 髋关节练习 <br>
                <pre>           (1) 弹力带前摆髋 10次 ×4组(左右各4组)</pre>    <br>
                <pre>           (2) 弹力带侧摆髋 10次 ×4组(左右各4组)</pre>    <br>
                <pre>           (3) 弹力带后摆髋 10次 ×4组(左右各4组)</pre>    <br>
                <pre>           (4) 弹力带弓步走  30m ×4组(左右各4组)</pre>    <br>
                <pre>           (5) 连续抬髋过栏架十次 ×4组(五个栏架)</pre>    <br>
                <pre>           (6) 连续侧向抬髋过栏架 10次 ×4组 </pre>    <br>
                <pre>           (7) 50m 途中跑×4(注意体会髋关节发力)</pre>    <br>
               
                
                3. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周三</h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 速度性练习 <br>
                <pre>           (1) 120m跑 ×4(85%)</pre>    <br>
                <pre>           (2) 80m跑  ×4(90%)</pre>    <br>
                <pre>           (3) 50m跑 ×4(100%)</pre>    <br>
                <pre>           (4) 30m跑 ×4(100%)</pre>    <br>
                <pre>           (5) 20m蹲踞式起跑 ×4(100%)</pre>    <br>
                
                3. 按摩 拉伸放松 积极恢复 <br>
                <br>
                
                <h4 >周四 </h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 弹性练习 <br>
                <pre>           (1) 单腿连续跳 50m×3</pre>    <br>
                <pre>           (2) 原地纵跳 20 次×3</pre>    <br>
                <pre>           (3) 连续跨步跳 50m×3</pre>    <br>
                <pre>           (4) 原地弓箭步交换跳 20 次×3</pre>    <br>
                <pre>           (5) 连续蛙跳 50m×3</pre>    <br>
                <pre>           (6) 原地收腹跳 20 次×3 </pre>    <br>
                <pre>           (7) 弹性加速跑 30m×3×2 组</pre>    <br>
                3. 按摩 拉伸放松 积极恢复<br>
                <br>
                <h4 >周五 </h4><br>   
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 速度耐力练习 <br>
                <pre>           (1) 600m跑 ×2(90%)</pre>    <br>
                <pre>           (2) 400m跑 ×2(95%)</pre>    <br>
                <pre>           (3) 200m跑 ×2(95%)</pre>    <br>
                <pre>           (4) 120m跑 ×2(100%)</pre>    <br>
                <pre>           (5) 50m跑  ×2(100%)</pre>    <br>
                3. 按摩 拉伸放松 积极恢复 <br>
                <br>

                <h4 >周六 </h4><br>     
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 力量练习(根据自身水平选择重量) <br>
                <pre>           (1) 高翻 4次 ×4组(80%1RM)(根据情况选择)</pre>    <br>
                <pre>           (2) 负重快速卧推 10~12次 ×4组</pre>    <br>
                <pre>           (3) 负重快速挺推 10~12次 ×4组</pre>    <br>
                <pre>           (4) 负重快速深蹲 8~10次 接 30m加速跑 ×4组</pre>    <br>
                <pre>           (5) 负重半蹲跳 8~10次 接 30m加速跑 ×4组</pre>    <br>
                <pre>           (6) 仰卧两头起 20次 ×4组 </pre>    <br>
                <pre>           (7) 俯卧两头起 20次 ×4组</pre>    <br>
                3. 按摩 拉伸放松 积极恢复  <br>
                <br>
                <h4 >周天</h4><br>    
                放松休息 积极恢复 <br>

                <p> <big style="color:red">注意:</big> 练习前热身和练习后放松需要认真进行 ,谨防受伤，以上训练 可重复训练数周 ，强度可根据自身水平循序渐进增加</p>
            <br>
                <div class="wenzhang">
                    <h2 id="C2" style="text-align: center;">派燃烧体育生训练计划(800m）</h2>
                </div>
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <!-- <div class="video11">
                    <video controls="controls" autoplay="autoplay" loop="loop"  muted="muted"
                    width="1200px" height="700px"
                    src="@/assets/images/plan800.mp4" alt="视频2" />
                </div> -->
                <h4 >周一</h4><br>
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br> 
                2. 跑的专门性练习(小步跑、高抬腿、后蹬跑)各30m ×2组<br> 
                3. 变速跑10 圈，直加速跑弯道慢跑放松 <br>
                <pre>           (1) 直道全力加速</pre>    <br>
                <pre>           (2) 弯道慢跑积极放松</pre>    <br>
                <pre>           (3) 中途不可停下或慢走</pre>    <br>
                <pre>           (4) 保持自己的节奏</pre>    <br>  
                4. 30m冲刺跑 ×2 <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周二</h4><br> 
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br>
                2. 100m跑 ×4(组间间歇3~5 分钟) <br>
                3. 素质练习(组间间歇50s 左右)<br>
                <pre>           (1) 仰卧起坐30 ×3组</pre>    <br>
                <pre>           (2) 俯卧背起30 ×3组</pre>    <br>
                <pre>           (3) 仰卧两头起20 ×3组</pre>    <br>
                <pre>           (4) 俯卧撑30 ×3组</pre>    <br>
                4. 放松800m跑 (70~80%) <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>
                

                <h4 >周三</h4><br> 
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br>
                2. 跑的专门性练习(小步跑、高抬腿、后蹬跑)各30m ×2组 <br>
                3. 耐力练习 <br>
                <pre>           (1) 400m跑 ×4(间歇2~4 分钟)</pre>    <br>
                <pre>           (2) 400m跑 ×2(间歇3~4 分钟)</pre>    <br>
                <pre>           (3) 600m跑 ×1(100%)</pre>    <br>
                <pre>           (4) 800m跑 ×1(80%)</pre>    <br>
                
                4. 按摩 拉伸放松 积极恢复 <br>
                <br>
                
                <h4 >周四 </h4><br> 
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br>
                2. 弹性练习 <br>
                <pre>           (1) 原地直膝跳20次 ×2组</pre>    <br>
                <pre>           (2) 原地弓箭步交换跳20 ×2组</pre>    <br>
                <pre>           (3) 单台阶单脚上下跳10次 ×4组(上下算一次)</pre>    <br>
                <pre>           (4) 单台阶左右脚交换跳20次 ×4组(左右算一次)</pre>    <br>
                <pre>           (5) 单台阶双脚上下跳20次 ×4组(上下算一次)</pre>    <br>
                <pre>           (6) 弹性跑30m ×2</pre>    <br>
                3. 放松800m跑(70~80%) <br>
                4. 按摩 拉伸放松 积极恢复<br>
                <br>
                <h4 >周五 </h4><br>   
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br>
                2. 跑的专门性练习(小步跑、高抬腿、后蹬跑)各30m×2 <br>
                3. 小测试 <br>
                <pre>           (1) 800m计时跑 ×1(120%)</pre>    <br>
                <pre>           (2) 800m跑 ×1(100%)</pre>    <br>
                <pre>           (3) 400m放松跑 ×2(60%)</pre>    <br>
                4. 按摩 拉伸放松 积极恢复 <br>
                <br>

                <h4 >周六 </h4><br>     
                1. 慢跑热身(4~6 圈) 拉伸韧带 活动关节 <br>
                2. 力量练习(根据自身水平选择重量) <br>
                <pre>           (1) 轻负重弓步走30m ×4组</pre>    <br>
                <pre>           (2) 轻负重潜蹲跳15次 ×4组</pre>    <br>
                <pre>           (3) 轻负重快速卧推15次 ×4组</pre>    <br>
                <pre>           (4) 轻负重快速俯身划船15次 ×4组</pre>    <br>
                3. 80m放松跑 ×4(60~70%)  <br>
                4. 按摩 拉伸放松 积极恢复  <br>
                <br>
                <h4 >周天</h4><br>    
                放松休息 积极恢复 <br>

                <p> <big style="color:red">注意:</big> 练习前热身和练习后放松需要认真进行 ,谨防受伤，以上训练 可重复训练数周 ，强度可根据自身水平循序渐进增加</p>
                <br>

                <div class="wenzhang">
                    <h2 id="C3" style="text-align: center;">派燃烧体育生训练计划(铅球）</h2>
                </div>
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <div class="video11">
                    <video controls="controls" autoplay="autoplay"   muted="muted"
                    width="1200px" height="700px"
                    src="@/assets/images/qianqiu.mp4" alt="视频3" />
                </div>
 
                <h4 >周一</h4><br>
                1. 慢跑热身 拉伸韧带 活动关节 <br> 
                2. 原地技术练习<br> 
                <pre>           (1) 原地蹬地转髋10次 ×4组</pre>    <br>
                <pre>           (2) 原地徒手推球动作10次 ×4组</pre>    <br>
                <pre>           (3) 原地换腿10次 ×4组</pre>    <br>
                <pre>           (4) 原地完整动作练习10次 ×4组</pre>    <br>  
                <pre>           (5) 原地推实心球练习20次</pre>    <br>  
                3. 30m冲刺跑×3 ×2组 <br>
                4. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周二</h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 原地推铅球10次 <br>
                3. 阻力带练习<br>
                <pre>           (1) 原地阻力带转髋10次 ×4组</pre>    <br>
                <pre>           (2) 原地阻力带推球动作10次 ×4组</pre>    <br>
                <pre>           (3) 原地阻力带站姿挺髋10次 ×4组</pre>    <br>
                <pre>           (4) 阻力带指卧撑8次 ×4组</pre>    <br>
                4. 推铅球10次（体会发力） <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周三</h4><br> 
                1. 慢跑热身  拉伸韧带 活动关节 <br>
                2. 原地推铅球10次 <br>
                3. 快速小力量练习 <br>
                <pre>           (1) 轻负重快速卧推10次 ×4组</pre>    <br>
                <pre>           (2) 轻负重深蹲跳10次 ×4组</pre>    <br>
                <pre>           (3) 轻负重快速挺推15次 ×4组</pre>    <br>
                <pre>           (4) 轻负重潜蹲跳15次 ×4组</pre>    <br>
                <pre>           (5) 30m冲刺跑 ×4</pre>    <br>
                4. 原地推实心球10次(注意体会发力速度) <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>
                
                <h4 >周四 </h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 推铅球练习 <br>
                <pre>           (1) 10次 ×2组(组间休息5 分钟)</pre>    <br>
                <pre>           (2) 组间做指卧撑10次</pre>    <br>
                3. 弹性练习 <br>
                <pre>           (1) 原地纵跳10次 ×2组</pre>    <br>
                <pre>           (2) 原地单脚跳10次 ×2组(左右脚换)</pre>    <br>
                <pre>           (3) 原地收腹模脚跳10次 ×2组</pre>    <br>
                4. 30m冲刺跑 ×4<br>
                5. 按摩 拉伸放松 积极恢复<br>
                <br>

                <h4 >周五 </h4><br>   
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 推铅球10次(根据自身水平选择重量) <br>
                3. 大力量练习 <br>
                <pre>           (1) 高翻4 次×4 组(80%1RM)(根据情况选择)</pre>    <br>
                <pre>           (2) 卧推一组一个(逐渐增加重量至无法推起)</pre>    <br>
                <pre>           (3) 深蹲一组一个(逐渐增加重量至无法推起)</pre>    <br>
                <pre>           (4) 卧推5RM ×4组</pre>    <br>
                <pre>           (5) 深蹲5RM ×4组</pre>    <br>
                <pre>           (6) 仰卧两头起20 ×3组</pre>    <br>
                <pre>           (7) 俯卧背起30 ×3组</pre>    <br>
                4. 30m冲刺跑 ×4 <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>

                <h4 >周六 </h4><br>     
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 推铅球练习 <br>
                <pre>           (1) 模拟考试推铅球2~3 次(计成绩)</pre>    <br>
                <pre>           (2) 推铅球10次 ×3组</pre>    <br>
                3. 30m冲刺跑 ×4  <br>
                4. 按摩 拉伸放松 积极恢复  <br>
                <br>
                <h4 >周天</h4><br>    
                放松休息 积极恢复 <br>
                 <p> <big style="color:red">注意:</big> 练习前热身和练习后放松需要认真进行 ,谨防受伤，以上训练 可重复训练数周 ，强度可根据自身水平循序渐进增加</p>
                
                 <div class="wenzhang">
                    <h2 id="C4" style="text-align: center;">派燃烧体育生训练计划(跳远）</h2>
                </div>
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <div class="video11">
                    <video controls="controls" autoplay="autoplay"   muted="muted"
                    width="1200px" height="700px"
                    src="@/assets/images/tiaoyuan.mp4" alt="视频4" />
                </div>
                <h4 >周一</h4><br>
                1. 慢跑热身 拉伸韧带 活动关节 <br> 
                2. 原地技术练习<br> 
                <pre>           (1) 原地快速摆臂10次 ×4组</pre>    <br>
                <pre>           (2) 原地快速摆臂屈髋下蹲10次 ×4组</pre>    <br>
                <pre>           (3) 原地屈髋姿势起跳10次 ×4组</pre>    <br>
                <pre>           (4) 原地完整起跳10次 ×4组</pre>    <br>  
                3. 立定跳远10次 (体会动作) <br>
                4. 30m冲刺跑×4  <br>
                5. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周二</h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 立定跳远10 次 <br>
                3. 弹性练习<br>
                <pre>           (1) 原地单脚跳15次× 3组（左右各15 次）</pre>    <br>
                <pre>           (2) 原地直膝跳15次× 3组</pre>    <br>
                <pre>           (3) 原地弓箭步交换跳10次 ×3组</pre>    <br>
                <pre>           (4) 原地收腹模脚跳10次 ×3组</pre>    <br>
                <pre>           (3) 30m单脚跳 ×3 </pre>    <br>
                <pre>           (4) 30m连续蛙跳 ×3 </pre>    <br>
                4. 立定跳远10 次(体会快速跳起) <br>
                5. 30m冲刺跑 ×4 <br>
                6. 按摩 拉伸放松 积极恢复 <br>
                <br>
                <h4 >周三</h4><br> 
                1. 慢跑热身  拉伸韧带 活动关节 <br>
                2. 立定跳远10 次 <br>
                3. 阻力带练习(体会静动快速爆发) <br>
                <pre>           (1) 阻力带原地摆臂10次 ×4组</pre>    <br>
                <pre>           (2) 阻力带快速摆臂屈髋下蹲10次 ×4组</pre>    <br>
                <pre>           (3) 阻力带屈髋姿势快速起跳10次 ×4组</pre>    <br>
                <pre>           (4) 阻力带完整起跳10次 ×4组</pre>    <br>
                <pre>           (5) 阻力带加速跑20m×4(需要人辅助)</pre>    <br>
                4. 立定跳远十次(体会快速发力) <br>
                5. 30m冲刺跑 ×4 <br>
                6. 按摩 拉伸放松 积极恢复 <br>
                <br>
                
                <h4 >周四 </h4><br> 
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 立定跳远10次 <br>
                3. 跳台阶练习 <br>
                <pre>           (1) 单台阶单脚上下跳10次 ×4组(上下算一次)</pre>    <br>
                <pre>           (2) 单台阶左右脚交换跳20次 ×4组(左右算一次)</pre>    <br>
                <pre>           (3) 单台阶双脚上下跳20次 ×4组(上下算一次)</pre>    <br>
                <pre>           (4) 单脚连续跳2台阶4~6次 ×4组(左右各四组)</pre>    <br>
                <pre>           (5) 双脚连续跳4台阶4~6次 ×4组</pre>    <br>
                <pre>           (6) 双脚连续跳台阶2~4次 ×4组(选取自身最大阶数)</pre>    <br>
                4. 30m冲刺跑 ×4<br>
                5. 按摩 拉伸放松 积极恢复<br>
                <br>

                <h4 >周五 </h4><br>   
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 力量练习(根据自身水平选择重量)<br>
                <pre>           (1) 高翻4 次×4 组(80%1RM)(根据情况选择)</pre>    <br>
                <pre>           (2) 轻负重快速卧推10~12次 ×4组</pre>    <br>
                <pre>           (3) 负重快速挺推10~12次 ×4组</pre>    <br>
                <pre>           (4) 负重快速深蹲 8~10 次接30m加速跑 ×4组</pre>    <br>
                <pre>           (5) 负重半蹲跳 8~10 次接30m加速跑 ×4组</pre>    <br>
                <pre>           (6) 仰卧两头起20次 ×4组</pre>    <br>
                <pre>           (7) 俯卧两头起20次 ×4组</pre>    <br>
                3. 按摩 拉伸放松 积极恢复 <br>
                <br>

                <h4 >周六 </h4><br>     
                1. 慢跑热身 拉伸韧带 活动关节 <br>
                2. 立定跳远练习 <br>
                <pre>           (1) 模拟考试跳远2~3 次(计成绩)</pre>    <br>
                <pre>           (2) 跳远10次 ×3组</pre>    <br>
                3. 30m冲刺跑 ×4  <br>
                4. 按摩 拉伸放松 积极恢复  <br>
                <br>
                <h4 >周天</h4><br>    
                放松休息 积极恢复 <br>

                <p> <big style="color:red">注意:</big> 练习前热身和练习后放松需要认真进行 ,谨防受伤，以上训练 可重复训练数周 ，强度可根据自身水平循序渐进增加</p>
                <div class="wenzhang">
                    <h2 id="C5" style="text-align: center;">美国约翰逊（前四百米记录保持者）冬训训练计划 </h2>
                </div>
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <h4 >星期一</h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.计时60米x2,300米x2(百分之八十)200米x1(百分之八十五)  <br>
                <br>
                <h4 >星期二 </h4><br>
                1.大步跑100米x10活动--专门练习<br>
                2.起跑器10米x5,20米x4,30米x3 <br>
                3.下午两点力量(高翻,深蹲)一小时多 <br>
                <br>
                <h4 >星期三 </h4><br>
                调整 <br>
                <br>
                <h4 >星期四 </h4> <br>
                1.大步跑100米x10活动--专门练习 <br>
                2.180米x2(百分之七十五) <br>
                3.150米x2(百分之八十) <br>
                4后肌肉练习x2 <br>
                <br>
                <h4 >星期五 上午 </h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.起跑器10米x5,20米x4,30米x2 <br>
                <br>
                <h4 >星期五 下午一点半 </h4><br>
                1.力量一小时十分钟(抓举.单腿蹲....) <br>
                <br>
                <h4 >星期六 </h4><br>
                蒸桑拿20分钟！小力量20分钟 <br>
                <br>
                <h4 >星期一 </h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2. 250米 180米 (胶鞋百分之70)<br>
                3. 150米 120米 (钉鞋百分之八十五) <br>
                <br>
                
                <h4 >星期二 </h4><br> 
                1.大步跑100米x10活动--专门练习 <br>
                2.跑格20米加80米,计时x3 <br>
                <br>
                <h4 >星期三 </h4><br> 
                 调整,游泳池放松 <br>
                 <br>
                 <h4 >星期四</h4><br> 
                1.大步跑100米x10活动--专门练习 <br>
                2.300米x2,百分之六十~七十  <br>
                3.200米一个,百分之八十 <br>
                <br>
                <h4 >星期五</h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.起跑器10米x5,20米x3,30米x2 <br>
                3.后肌练习 <br>
                <br>
                <h4 >星期六 </h4><br>
                 高翻,深蹲,卧推,力量一小时 <br>
                 <br>
                 <h4 >星期一 </h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.两个300米,百分之七十五~八十速度 <br>
                3.一个200米,百分之八十五速度 <br>
                <br>
                <h4 >星期二 </h4><br>
                1.大步跑100米x100活动--专门练习 <br>
                2.起跑器10米x5 <br>
                3.四十米格子60米(2.4米)加80米x3 <br>
                4.下午两点力量(高翻....一小时十分钟) <br>
                <br>
                <h4 >星期三</h4><br>
                休息<br>
                <br>
                <h4 >星期四</h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.起跑器10米x5  <br>
                3.60米40米栏架加80米40米栏架x3 <br>
                <br>
                <h4 >星期五 </h4><br>
                1.大步跑100米x10活动--专门练习 <br>
                2.起跑器10米x10,20米x4,30米x2 <br>
                3.后肌练习 <br>
                <br>
                <h4 >星期六 </h4><br>
                大力量 <br>
                <br>
                <div class="wenzhang">
                    <h2 id="C6" style="text-align: center;">国家队教练 冬训训练计划  </h2>
                </div>
                <h3 style="text-align:right;">去奔跑 去征服所有不服</h3> <br> 
                <h4 >周一 </h4><br>
                上午小力量,身体素质,下午跑3个200mx2 <br>
                <br>
                <h4 >周二</h4><br> 
                起跑 10米4个,30米3个,60米1,一百米变速跑3个不记时  <br>
                <br>
                <h4 >周三</h4><br> 
                上午上肢力量为主,下午休息 <br>
                <br>
                <h4 >周四</h4><br> 
                跑格 60mx3x2 <br>
                <h4 > 周五</h4><br> 
                    150mx3x2 <br>
                    <br>
                <h4 > 周六</h4><br> 
                大力量,高翻,深蹲,后肌等力量练习。 <br>
                <br>
                <h4 > 周一</h4><br> 
                上午小力量,身体素质,下午跑3个200mx2  <br>
                <br>
                <h4 > 周二</h4><br> 
                起跑10米4个,30米3个,60米1,一百米变速跑3个不记时  <br>
                <br>
                <h4 >周三</h4><br> 
                上午上肢力量为主 ,下午休息 <br>
                <br>
                <h4 > 周四</h4><br> 
                调整  <br>
                <br>
                <h4 > 周五</h4><br> 
                跑步机,跨步跳100米x6  <br>
                <br>
                <h4 > 周六</h4><br> 
                高翻,半蹲,卧推等大力量课 <br>
                <br>
        </div>
    </div>
    
</template>
    
<script>
export default {
    name: 'TrainingPlan'
}
</script>
<style scoped>
.plan{
    width: 100%;
    height: 408px;
    background-color: #ccc;
}
 
.wenzhang {
    height: 60px;
    line-height:60px;
}

.wenben {
    width: 700px;
    font-size: 18px;
    margin-left: 250px;
    margin-bottom: 50px;
    background: url(../../assets/images/water.jpg) center repeat;
    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select:none;
    -khtml-user-select:none;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none;  
}

.video11{
    margin-left: -250px;
}
 
</style>
